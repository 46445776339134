import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState  } from '../store';
// import { DetailsType } from '../types/Details';

type DetailsState = {
  // details: DetailsType[];
  details: any;
}

const initialState: DetailsState = {
  details: [],
};

export const detailsSlice = createSlice({
  name: "details",
  initialState,
  reducers: {
    setDetails: (state, action: PayloadAction<any>) => {
      state.details = action.payload;
    },
  }
});

// Export actions from the slice
export const {
  setDetails,
} = detailsSlice.actions;

// Selectors to extract data from the state
export const getDetails = (state: RootState) => state.details.details;

export default detailsSlice.reducer;
