import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState  } from '../store';
// import { KsoType } from '../types/Kso';

type KsoState = {
  // kso: KsoType[];
  kso: any;
}

const initialState: KsoState = {
  kso: [],
};

export const ksoSlice = createSlice({
  name: "kso",
  initialState,
  reducers: {
    setKso: (state, action: PayloadAction<any>) => {
      state.kso = action.payload;
    },
  }
});

// Export actions from the slice
export const {
  setKso,
} = ksoSlice.actions;

// Selectors to extract data from the state
export const getKso = (state: RootState) => state.kso.kso;

export default ksoSlice.reducer;
