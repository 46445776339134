import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import App from "./App";
import AuthContext from "./context/AuthContext";
import GraphQlProvider from "./GraphQL/GraphQLProvider";
import ThemeProvider from "./context/ThemeContext";
import "./i18n";
import LanguageProvider from "./context/LanguageContext";
import { Provider } from 'react-redux';
import store from "./redux/store";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

root.render(
  <ThemeProvider>
    <CssBaseline />
    <AuthContext>
      <GraphQlProvider>
        <LanguageProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </LanguageProvider>
      </GraphQlProvider>
    </AuthContext>
  </ThemeProvider>
);
