import { Stack, Typography, Divider, IconButton, useTheme } from "@mui/material";
import { formatDateAndTime } from "../../../Global/Utils/commonFunctions";
import { ElpromFileMeta } from "../API/apiElpromSnippets";
import cssFontStyles from "../../../Global/Styles/font";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { useState } from "react";
import {
  AutocompleteOption,
  FormStatuses,
  SelectOption,
} from "../../../Global/Types/commonTypes";
import DownloadIcon from "@mui/icons-material/Download";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import Alert from "../../../Components/MaterialUI/Alert";
import { useLanguageContext } from "../../../context/LanguageContext";
import {
  getQueryElpromCollateralDetailsFile,
  getQueryElpromCompareFile,
  getQueryElpromMaterialsCompareFile,
  getQueryElpromMaterialsFile,
} from "../API/apiElpromGetQueries";
import Modal from "../../../Components/MaterialUI/Modal";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import Select from "../../../Components/MaterialUI/FormFields/Select";
import Button from "../../../Components/MaterialUI/Button";
import Autocomplete from "../../../Components/MaterialUI/FormFields/Autocomplete";
import ElpromFilesStageForm from "./ElpromFilesStageForm";

type ExtraFileType = "details" | "compare" | "materials" | "discrepancies";
const EXTRA_FILES: {
  file_name: string;
  type: ExtraFileType;
}[] = [
  {
    file_name: "Collateral",
    type: "details",
  },
  {
    file_name: "Collateral Compare",
    type: "compare",
  },
  {
    file_name: "Materials",
    type: "materials",
  },
  {
    file_name: "Materials Discrepancies",
    type: "discrepancies",
  },
];

interface ElpromFilesModalProps {
  filesMeta: ElpromFileMeta[];
  handleFetchTableData: () => Promise<void>;
  datesWithData: SelectOption[];
  initialSelectedDate: string;
  initialCompareDate: string;
  clientOrderOptions: SelectOption[];
}

const ElpromFilesModal: React.FC<ElpromFilesModalProps> = ({
  filesMeta,
  handleFetchTableData,
  datesWithData,
  initialSelectedDate,
  initialCompareDate,
  clientOrderOptions,
}) => {
  const { t, language } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles, ...cssFontStyles };

  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses | null>(null);

  const [innerModal, setInnerModal] = useState<ExtraFileType | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>(initialSelectedDate);
  const [compareDate, setCompareDate] = useState<string>(initialCompareDate);
  const [innerAlertMessage, setInnerAlertMessage] = useState<string | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<AutocompleteOption | null>(null);

  const { setAuthedUser } = useAuthedContext();

  const handleDownloadFile = async (link: string) => {
    try {
      if (!link) {
        return;
      }
      const downloadLink = document.createElement("a");
      downloadLink.href = `https://${link}`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (err) {
      console.log("err: ", err);
      setAlertMessage("Something went wrong");
      setAlertStatus("error");
    }
  };

  const handleDownloadExtra = async (type: ExtraFileType) => {
    try {
      if (!selectedDate) {
        setInnerAlertMessage(t("You must select a date"));
        setAlertStatus("warning");
        return;
      }
      if ((type === "compare" || type === "discrepancies") && !compareDate) {
        setInnerAlertMessage(t("You must select a compare date"));
        setAlertStatus("warning");
        return;
      }
      if ((type === "materials" || type === "discrepancies") && !selectedOrder?.value) {
        setInnerAlertMessage(t("You must select a client order"));
        setAlertStatus("warning");
        return;
      }
      setInnerAlertMessage(t("Fetching file"));
      setAlertStatus("loading");

      let result: any = null;
      switch (type) {
        case "details": {
          result = await callApi<any>({
            query: getQueryElpromCollateralDetailsFile(language as any, selectedDate),
            auth: { setAuthedUser },
          });
          break;
        }
        case "compare": {
          result = await callApi<any>({
            query: getQueryElpromCompareFile(language as any, selectedDate, compareDate),
            auth: { setAuthedUser },
          });
          break;
        }
        case "materials": {
          result = await callApi<any>({
            query: getQueryElpromMaterialsFile(
              language as any,
              selectedDate,
              selectedOrder!.value
            ),
            auth: { setAuthedUser },
          });
          break;
        }
        case "discrepancies": {
          result = await callApi<any>({
            query: getQueryElpromMaterialsCompareFile(
              language as any,
              selectedDate,
              selectedOrder!.value,
              compareDate
            ),
            auth: { setAuthedUser },
          });
          break;
        }
      }

      const blob = await result.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `collateral_${type}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.log("err ", err);
    }
    setInnerAlertMessage(null);
    setAlertStatus(null);
  };

  const handleOpenInner = (type: ExtraFileType) => {
    setInnerModal(type);
    setAlertStatus(null);
    setInnerAlertMessage(null);
  };

  return (
    <Stack spacing={3}>
      <Alert
        css={[styles.widthLimit20, styles.marginHorizontalAuto, styles.contentBreak]}
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={alertStatus}
      />

      <ElpromFilesStageForm
        filesMeta={filesMeta}
        handleFetchTableData={handleFetchTableData}
      />

      <Divider flexItem />
      <Typography css={styles.bolderText} variant="h4" textAlign="center">
        {t("Download Files")}
      </Typography>

      {filesMeta.map((item) => (
        <Stack
          key={`${item.id}-${item.file_type}`}
          spacing={3}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{marginTop: "5px"}}
        >
          <Typography css={styles.bolderText} variant="body1">
            {item.file_type}
          </Typography>
          <Card
            item={item}
            handleDownloadFile={handleDownloadFile}
            alertStatus={alertStatus}
          />
        </Stack>
      ))}

      <Divider flexItem style={{margin: "15px 0 10px"}} />

      {EXTRA_FILES.map((item) => (
        <Stack
          key={item.file_name}
          spacing={3}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{marginTop: "0"}}
        >
          <Typography css={styles.bolderText} variant="body1">
            {item.file_name}
          </Typography>

          <IconButton
            aria-label="download file"
            onClick={() => handleOpenInner(item.type)}
          >
            <DownloadIcon color="info" />
          </IconButton>
        </Stack>
      ))}

      <Modal
        open={!!innerModal}
        onClose={() => setInnerModal(null)}
        fullWidth
        maxWidth={"sm"}
        label={t("Download File")}
      >
        {innerModal ? (
          <Stack spacing={3} alignItems="center" justifyContent="center">
            <Stack
              css={[styles.width100]}
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Select
                css={[styles.width100, styles.widthLimit10]}
                label={t("Date")}
                selectOptions={datesWithData.filter((item) => item.value !== compareDate)}
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                disabled={alertStatus === "loading"}
              />
              {innerModal === "compare" || innerModal === "discrepancies" ? (
                <>
                  <CompareArrowsIcon />
                  <Select
                    css={[styles.width100, styles.widthLimit10]}
                    label={t("Compare Against")}
                    selectOptions={datesWithData.filter(
                      (item) => item.value !== selectedDate
                    )}
                    value={compareDate}
                    onChange={(e) => setCompareDate(e.target.value)}
                    disabled={alertStatus === "loading"}
                  />
                </>
              ) : null}
              {innerModal === "materials" || innerModal === "discrepancies" ? (
                <Autocomplete
                  css={[styles.width100, styles.widthLimit10]}
                  label={t("Client Order")}
                  options={clientOrderOptions}
                  value={selectedOrder}
                  handleOnChange={(val: AutocompleteOption) => setSelectedOrder(val)}
                  disabled={alertStatus === "loading"}
                />
              ) : null}
            </Stack>

            <Button
              css={[styles.width100, styles.widthLimit15]}
              color="primary"
              onClick={() => handleDownloadExtra(innerModal)}
              disabled={alertStatus === "loading"}
            >
              {t("Download File")}
            </Button>

            <Alert
              css={[
                styles.widthLimit30,
                styles.marginHorizontalAuto,
                styles.contentBreak,
              ]}
              message={innerAlertMessage || ""}
              showAlert={!!innerAlertMessage}
              severity={alertStatus}
            />
          </Stack>
        ) : null}
      </Modal>
    </Stack>
  );
};

export default ElpromFilesModal;

interface CardProps {
  item: ElpromFileMeta;
  alertStatus: FormStatuses;
  handleDownloadFile: (link: string) => Promise<void>;
}

const Card: React.FC<CardProps> = ({ item, handleDownloadFile, alertStatus }) => {
  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      divider={<Divider flexItem orientation="vertical" />}
    >
      <Typography variant="body1">{item.file_name}</Typography>
      <Typography variant="body1">{formatDateAndTime(item.uploaded_at)}</Typography>

      <IconButton
        aria-label="download file"
        onClick={() => handleDownloadFile(item.file_url)}
        disabled={alertStatus === "loading"}
        sx={
          item.file_type !== "priorities"
            ? undefined
            : {
                zIndex: "-1",
              }
        }
      >
        <DownloadIcon color="secondary" />
      </IconButton>
    </Stack>
  );
};
