import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState  } from '../store';
// import { PricesType } from '../types/Prices';

type PricesState = {
  // prices: PricesType[];
  prices: any;
}

const initialState: PricesState = {
  prices: [],
};

export const pricesSlice = createSlice({
  name: "prices",
  initialState,
  reducers: {
    setPrices: (state, action: PayloadAction<any>) => {
      state.prices = action.payload;
    },
  }
});

// Export actions from the slice
export const {
  setPrices,
} = pricesSlice.actions;

// Selectors to extract data from the state
export const getPrices = (state: RootState) => state.prices.prices;

export default pricesSlice.reducer;
