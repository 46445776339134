import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState  } from '../store';
// import { NotDistributedType } from '../types/NotDistributed';

type NotDistributedState = {
  // notDistributed: NotDistributedType[];
  notDistributed: any;
}

const initialState: NotDistributedState = {
  notDistributed: [],
};

export const notDistributedSlice = createSlice({
  name: "notDistributed",
  initialState,
  reducers: {
    setNotDistributed: (state, action: PayloadAction<any>) => {
      state.notDistributed = action.payload;
    },
  }
});

// Export actions from the slice
export const {
  setNotDistributed,
} = notDistributedSlice.actions;

// Selectors to extract data from the state
export const getNotDistributed = (state: RootState) => state.notDistributed.notDistributed;

export default notDistributedSlice.reducer;
