import { configureStore } from '@reduxjs/toolkit'
import detailsSlice from './Elprom/detailsSlice';
import ksoSlice from './Elprom/ksoSlice';
import supcoSlice from './Elprom/supcoSlice';
import pricesSlice from './Elprom/pricesSlice';
import notDistributedSlice from './Elprom/notDistributedSlice';
import pricesPoSlice from './Elprom/pricesPoSlice';

export const store = configureStore({
  reducer: {
    details: detailsSlice,
    kso: ksoSlice,
    supco: supcoSlice,
    prices: pricesSlice,
    notDistributed: notDistributedSlice,
    pricesPo: pricesPoSlice,
  }
})

export default store;

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store