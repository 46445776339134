import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState  } from '../store';
// import { SupcoType } from '../types/Supco';

type SupcoState = {
  // supco: SupcoType[];
  supco: any;
}

const initialState: SupcoState = {
  supco: [],
};

export const supcoSlice = createSlice({
  name: "supco",
  initialState,
  reducers: {
    setSupco: (state, action: PayloadAction<any>) => {
      state.supco = action.payload;
    },
  }
});

// Export actions from the slice
export const {
  setSupco,
} = supcoSlice.actions;

// Selectors to extract data from the state
export const getSupco = (state: RootState) => state.supco.supco;

export default supcoSlice.reducer;
