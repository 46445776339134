import { Query } from "../callApi";
import { PostQueryUserDeputyAccessControlInput } from "./apiUserDeputyInput";

export const postQueryUserDeputyAccessControl = (
  input: PostQueryUserDeputyAccessControlInput
): Query => ({
  endpoint: `users/access_control/user_deputy`,
  method: "POST",
  variables: input,
});

export const putQueryUserDeputyAccessControl = (
  user_deputy_id: string,
  input: PostQueryUserDeputyAccessControlInput
): Query => ({
  endpoint: `users/access_control/user_deputy/${user_deputy_id}`,
  method: "PUT",
  variables: input,
});

export const deleteQueryUserDeputyAccessControl = (user_deputy_id: string): Query => ({
  endpoint: `users/access_control/user_deputy/${user_deputy_id}`,
  method: "DELETE",
});
