import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState  } from '../store';
// import { PricesPOType } from '../types/PricesPO';

type PricesPOState = {
  // pricesPo: PricesPOType[];
  pricesPo: any;
}

const initialState: PricesPOState = {
  pricesPo: [],
};

export const pricesPoSlice = createSlice({
  name: "pricesPo",
  initialState,
  reducers: {
    setPricesPO: (state, action: PayloadAction<any>) => {
      state.pricesPo = action.payload;
    },
  }
});

// Export actions from the slice
export const {
  setPricesPO,
} = pricesPoSlice.actions;

// Selectors to extract data from the state
export const getPricesPO = (state: RootState) => state.pricesPo.pricesPo;

export default pricesPoSlice.reducer;
